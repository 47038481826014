import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import documentSearch from "./documents/documentSearchReducer";
import ressorter from "./ressorter/ressortReducer";
import aargange from "./aargang/aargangReducer";
import tags from "./tags/tagsReducer";
import contact from "./contact/contactTextReducer";
import maintenanceMessagesReducer from "./maintenance/maintenanceMessagesReducer";
import texts from "./texts/textsReducer";
import footer from "./footer/footerReducer";
import notes from "./notes/notesReducer";
import thunk from "redux-thunk";

const rootReducer = combineReducers({
  documentSearch,
  ressorter,
  aargange,
  maintenanceMessagesReducer,
  tags,
  contact,
  texts,
  footer,
  notes,
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
  const store = createStore(
    rootReducer,
    compose(applyMiddleware(...[thunk]), (window as any).__REDUX_DEVTOOLS_EXTENSION__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f)
  );

  return store;
}

import { createAction, ActionsUnion } from "../actionHelper";
import { apiGET } from "../api";
import { IReprintNote } from "../../models/IReprintNote";
import { Dispatch } from "redux";

// Action Constants
const RECEIVE_REPRINT_NOTES = "RECEIVE_REPRINT_NOTES";

// Action creators
const receiveReprintNotes = (notes: IReprintNote[]) => createAction(RECEIVE_REPRINT_NOTES, notes);

const actions = { receiveReprintNotes };
type ReprintActions = ActionsUnion<typeof actions>;

const defaultState: IReprintState = {
  reprintNotes: [],
};

const getReprintNotes = (documentId: number) => async (dispatch: Dispatch<ReprintActions>) => {
  const url = "api/document/" + documentId + "/reprintNotes";
  const result = await apiGET<IReprintNote[]>(url);

  dispatch(receiveReprintNotes(result));
};

// Action exports
export interface IReprintInformationActions {
  getReprintNotes: (documentId: number) => void;
}

export const ReprintInformationActions: IReprintInformationActions = {
  getReprintNotes,
};

export interface IReprintState {
  reprintNotes: IReprintNote[];
}

export default (state: IReprintState = defaultState, action: ReprintActions): IReprintState => {
  switch (action.type) {
    case RECEIVE_REPRINT_NOTES: {
      return {
        ...state,
        reprintNotes: action.payload,
      };
    }
    default:
      return state;
  }
};

import * as React from "react";
import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router";
import Layout from "./components/Layout";
const Home = lazy(() => import("./components/Home"));
const AargangSearch = lazy(() => import("./components/AargangSearch"));
const DocumentsContainer = lazy(() => import("./containers/documents/DocumentsContainer"));
const NotFoundContainer = lazy(() => import("./containers/error/NotFoundContainer"));
import GlobalErrorContainer from "./containers/error/GlobalErrorContainer";
import TextContainer from "./containers/text/TextContainer";
import { TextType } from "./models/TextType";

export const routes = (
  <Layout>
    <Suspense fallback={<div></div>}>
      <Switch>
        <Route exact={true} path="/" component={Home} />
        <Route exact={true} path="/volumesearch" component={AargangSearch} />
        <Route path="/documents" component={DocumentsContainer} />
        <Route path="/about">
          <TextContainer type={TextType.About} title="Ministerialtidende | Om" />
        </Route>
        <Route path="/contact">
          <TextContainer type={TextType.Contact} title="Ministerialtidende | Kontakt" />
        </Route>
        <Route path="/error" component={GlobalErrorContainer} />
        <Route path="*" component={NotFoundContainer} />
      </Switch>
    </Suspense>
  </Layout>
);

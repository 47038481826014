import { createAction, IActionWithPayload } from "../actionHelper";
import { apiGET } from "../api";
import { IRessort } from "../../models/IRessort";
import { AppState } from "..";

const RECEIVE_RESSORTER = "RECEIVE_RESSORTER";

// API
const GETRessorter = () => apiGET<IRessort[]>("api/ressort");

const receiveRessorter = (ressort: IRessort[]) => createAction(RECEIVE_RESSORTER, ressort);

const getRessorter = () => async (dispatch: any, getState: () => AppState) => {
  const { ressorter } = getState();
  if (ressorter.length === 0) {
    const ressortResult = await GETRessorter();
    dispatch(receiveRessorter(ressortResult));
  }
};

// Actions Interface
export interface IRessortActions {
  getRessorter: () => void;
}

export const RessortActions: IRessortActions = {
  getRessorter,
};

export default (state: IRessort[] = [], action: IActionWithPayload<string, IRessort[]>): IRessort[] => {
  switch (action.type) {
    case RECEIVE_RESSORTER:
      return [...action.payload];
    default:
      return state;
  }
};

import { createAction, IActionWithPayload } from "../actionHelper";
import { apiGET } from "../api";
import { AppState } from "..";
import { IAargang } from "./../../models/IAargang";
import { IAargangeState } from "./IAargangeState";

const RECEIVE_AARGANGE = "RECEIVE_AARGANGE";

// API
const GETAargange = () => apiGET<IAargang[]>("api/volumes");

const receiveAargange = (aargange: IAargang[]) => createAction(RECEIVE_AARGANGE, aargange);

const getAargange = () => async (dispatch: any, getState: () => AppState) => {
  const { aargange } = getState();
  if (aargange.aargange.length === 0) {
    const aargangeResult = await GETAargange();
    dispatch(receiveAargange(aargangeResult));
  }
};

// Actions Interface
export interface IAargangeActions {
  getAargange: () => void;
}

export const AargangeActions: IAargangeActions = {
  getAargange,
};

export const defaultAargangeState: IAargangeState = {
  aargange: [],
  getRessortName: () => "",
};

export default (state: IAargangeState = defaultAargangeState, action: IActionWithPayload<string, IAargang[]>): IAargangeState => {
  switch (action.type) {
    case RECEIVE_AARGANGE:
      const allRessorter = action.payload.flatMap(aa => aa.ressorter);
      return {
        ...state,
        aargange: [...action.payload],
        getRessortName: id => {
          const ressort = allRessorter.find(r => r.id.toString() === id);
          return ressort ? ressort.name : "";
        },
      };
    default:
      return state;
  }
};

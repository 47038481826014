import { createAction, IActionWithPayload } from "../actionHelper";
import { ParsedParams, parseQueryString, parseWithDefaults } from "@lexdania/components";

// Action Constants
const SET_PARAMS = "SET_PARAMS";

export type DocumentSearchParams = {
  page: number;
  ps: number;
  o: number;
  d: boolean;
  yh: number;
  yl: number;
  r: string[];
  hr: string[];
  dt: string[];
  e: string[];
  dnr: string;
  t: string[];
  ttl: string[];
  pttl: string[];
};

export interface IDocumentSearchState {
  params: string;
  paramsParsed: ParsedParams;
  parseWithDefaults: (defaults: DocumentSearchParams) => DocumentSearchParams;
}

// Action Exports
export interface IDocumentSearchActions {
  setParams: (params: string) => void;
}

export const DocumentSearchActions: IDocumentSearchActions = {
  setParams: (params: string) => createAction(SET_PARAMS, params),
};

const defaultState: IDocumentSearchState = {
  params: "",
  paramsParsed: {},
  parseWithDefaults: defaults => parseWithDefaults({}, defaults), // !Important to keep this as it does a spread of the "empty marker arrays" EmptyNumberArray and EmptyNumberArray
};

export default (state: IDocumentSearchState = defaultState, action: IActionWithPayload<string, string>): IDocumentSearchState => {
  switch (action.type) {
    case SET_PARAMS: {
      const params = action.payload;
      const parsed: ParsedParams = parseQueryString(params);
      return {
        ...state,
        params: params,
        paramsParsed: parsed,
        parseWithDefaults: defaults => parseWithDefaults(parsed, defaults),
      };
    }
    default:
      return state;
  }
};

import { ITag } from "../../models/ITag";
import { createAction, IActionWithPayload, ActionsUnion } from "../actionHelper";
import { Dispatch } from "redux";
import { apiGET } from "../api";
import { AppState } from "..";

const RECEIVE_TAG = "RECEIVE_TAG";

const GETTags = () => apiGET<ITag[]>("api/tags");

const receiveTags = (ressort: ITag[]) => createAction(RECEIVE_TAG, ressort);

const getTags = () => async (dispatch: Dispatch<TagActionTypes>, getState: () => AppState) => {
  const { tags } = getState();
  if (tags.length === 0) {
    const tagResult = await GETTags();
    dispatch(receiveTags(tagResult));
  }
};

const Actions = { receiveTags };
type TagActionTypes = ActionsUnion<typeof Actions>;

export interface ITagActions {
  getTags: () => void;
}

export const TagActions: ITagActions = {
  getTags,
};

export default (state: ITag[] = [], action: IActionWithPayload<string, ITag[]>): ITag[] => {
  switch (action.type) {
    case RECEIVE_TAG:
      return [...action.payload];
    default:
      return state;
  }
};

import * as React from "react";
import "./_Layout.scss";
import packageJson from "../../package.json";
import { Container } from "reactstrap";
import { HeaderContainer } from "../containers/HeaderContainer";
import FooterContainer from "../containers/FooterContainer";
import { RouteComponentProps, withRouter } from "react-router";
import { DevelopmentSizeHelper } from "@lexdania/components";
import { setupApi } from "../store/api";
import MaintenanceContainer from "../containers/MaintenanceContainer";

class Layout extends React.Component<RouteComponentProps<{}>, {}> {
  constructor(props: RouteComponentProps<{}>) {
    super(props);

    setupApi(this.updateState);
  }

  public render() {
    return (
      <React.Fragment>
        <MaintenanceContainer />
        <div className="main-content-body">
          <div hidden={true}>{"lexdania/components version: " + packageJson.dependencies["@lexdania/components"]}</div>
          {process.env.REACT_IS_DEVELOPMENT && <DevelopmentSizeHelper />}
          <HeaderContainer />
          <Container className="content-area">{this.props.children}</Container>
          <FooterContainer />
        </div>
      </React.Fragment>
    );
  }

  public updateState = (errorCode: number) => {
    this.props.history.push("/error", { errorCode });
  };
}

export default withRouter(Layout);

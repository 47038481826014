import React from "react";
import { Footer } from "@lexdania/components";
import { IFooterFetchers, FooterReducerActions, IExternalLinks } from "./../store/footer/footerReducer";
import { AppState } from "../store";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

interface IFooterContainerProps extends IFooterFetchers {
  externalLinks: IExternalLinks;
}

class FooterContainer extends React.Component<IFooterContainerProps, {}> {
  public componentDidMount(): void {
    this.props.getExternalLinks();
  }

  public render() {
    const wasLink = this.props.externalLinks.wasLink;
    const bottomLinks = [
      { text: "Om Ministerialtidende", url: "/about" },
      { text: "Kontakt", url: "/contact" },
      { text: wasLink.text, url: wasLink.url, isExternal: true },
    ];
    const listLinks = this.props.externalLinks.listLinks;
    return (
      <Footer aboutHeader="Om" bottomLinks={bottomLinks} externalLinks={listLinks}>
        Ministerialtidende udkommer pr. 1. januar 2013 ikke længere. Her findes det elektroniske Ministerialtidende, årgangene 2008-2012. Ministerialtidende har
        tjent som offentliggørelsesmedie for administrative, kongelige eller ministerielle befalinger, samt administrative afgørelser af almindelig interesse.
        <br />
        På ministerialtidende.dk anvendes der ikke cookies.
      </Footer>
    );
  }
}

export default connect(
  ({ footer }: AppState, ownProps: any) => ({
    ...ownProps,
    ...{ externalLinks: footer.externalLinks },
  }),
  dispatch =>
    bindActionCreators(
      {
        ...FooterReducerActions,
      },
      dispatch
    )
)(FooterContainer);

import { ActionsUnion, createAction, IActionWithPayload } from "../actionHelper";
import { Dispatch } from "redux";
import { apiGET } from "../api";

// Action Constants
const GET_CONTACT_TEXT = "GET_CONTACT_TEXT";

export interface IContactTextState {
  contactText: string;
}

// Local actions
const setContactText = (text: string) => createAction(GET_CONTACT_TEXT, text);

const getContactText = () => async (dispatch: Dispatch<LocalActions>) => {
  const text = await apiGET<string>("text/contact.html");
  dispatch(setContactText(text));
};

// External actions
export interface IContactTextActions {
  getContactText: () => void;
}

export const ContactTextActions: IContactTextActions = {
  getContactText: getContactText,
};

const Actions = { setContactText };
type LocalActions = ActionsUnion<typeof Actions>;

const defaultState: IContactTextState = {
  contactText: "",
};

export default (state: IContactTextState = defaultState, action: IActionWithPayload<string, string>): IContactTextState => {
  switch (action.type) {
    case GET_CONTACT_TEXT:
      return { ...state, ...{ contactText: action.payload } };
    default:
      return state;
  }
};
